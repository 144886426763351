import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "sorokin" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Sorokin extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>A versatile wing-back, knows his tactics, able to cover for his partners and make use of strong body-to-body play.</p>
          <p>Can play left and right, is great with both feet. Adapts both to the roles of a wing-back (3-5-2) or a full-back.</p>
          <p>Is a tough tackler, knows how to impose duels on opponents in difficult situations.</p>
          <p>Is good in the air. Serves throw-ins far and accurately.</p>
          <p>Is playing for Latvia U21.</p>
        </div>
      </div>
    )
  }
}